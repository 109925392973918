document.addEventListener('DOMContentLoaded', function() {
  if ($(".announcement").length) {
    const announcement = document.querySelector('.announcement');
    if (announcement.offsetHeight > 0) {
      $('body:not(.template-homepage)').css('margin-top', announcement.offsetHeight);
    }
  }
});

$(document).ready(function() {
  // scroll
  $(window).on("scroll", function() {
    if ($(this).scrollTop() > 50) {
      if (!$(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").addClass("navbar--scrolled");
        $(".announcement").slideUp(500);
      }
    } else {
      if ($(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").removeClass("navbar--scrolled");
        $(".announcement").slideDown(500);
      }
    }
  });

  // menu
  const menuOpen = () => {
    $("body").css("overflow", "hidden");
    $("#menu").addClass("menu--opened");
    $(".navbar").addClass("navbar--scrolled");
    $(".announcement").slideUp(500);
    $(".menu__wrapper")
      .delay(600)
      .fadeIn(250);
    setTimeout(function() {
      $(".menu__arrow").addClass("menu__arrow--opened");
    }, 800);
  };

  const menuClose = () => {
    $(".menu__arrow").removeClass("menu__arrow--opened");
    $(".menu__wrapper").fadeOut(250);
    setTimeout(function() {
      $("#menu").removeClass("menu--opened");
    }, 250);
    setTimeout(function() {
      $("body").css("overflow", "auto");
      if (!($(window).scrollTop() > 50)) {
        $(".navbar").removeClass("navbar--scrolled");
        $(".announcement").slideDown(500);
      }
    }, 500);
  };

  $(".hamburger").on("mouseenter", event => {
    $("#hamburgerMenu").slideUp(250);
    setTimeout(function() {
      if ($(".hamburger").hasClass("hamburger--clicked")) {
        $("#hamburgerClose").slideDown(250);
      } else {
        $("#hamburgerOpen").slideDown(250);
      }
    }, 0);
  });

  $(".hamburger").on("mouseleave", event => {
    $("#hamburgerOpen, #hamburgerClose").slideUp(250);
    setTimeout(function() {
      $("#hamburgerMenu").slideDown(250);
    }, 0);
  });

  $(".hamburger").on("click", function() {
    $(this).toggleClass("hamburger--clicked");
    if ($(this).hasClass("hamburger--clicked")) {
      menuOpen();
    } else {
      menuClose();
    }
  });

  $(".nav-item.dropdown").on("mouseover", event => {
    $(event.currentTarget).find(".dropdown-menu").addClass("show");
    $(event.currentTarget).find(".navbar__link").addClass("active");
  });

  $(".nav-item.dropdown").on("mouseout", event => {
    $(event.currentTarget).find(".dropdown-menu").removeClass("show");
    $(event.currentTarget).find(".navbar__link").removeClass("active");
  });
});
