$(document).ready(() => {
  const imgClass = "lzy_img";
  const imgSelector = "img." + imgClass;

  const imageObserver = new IntersectionObserver((entries, imgObserver) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const lazyImage = entry.target;
        lazyImage.src = lazyImage.dataset.src;
        lazyImage.classList.remove(imgClass);
        imgObserver.unobserve(lazyImage);
      }
    });
  });

  const images = document.querySelectorAll(imgSelector);
  images.forEach(image => {
    imageObserver.observe(image);
  });
});
