// Font Awesome
// Font icon set

import { config, library, dom } from "@fortawesome/fontawesome-svg-core";

// Change the config to fix the flicker
config.mutateApproach = "sync";

import {
  faSearch,
  faTimes,
  faAngleRight,
  faPhoneAlt,
  faAngleLeft,
  faMapMarkerAlt,
  faEnvelope,
  faPrint,
  faPlus,
  faMinus,
  faAngleDown,
  faCalendarAlt,
  faClock,
  faCalendar,
  faRss,
  faArrowAltCircleRight,
  faLongArrowAltRight,
  faLongArrowAltLeft
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faFacebookSquare,
  faPinterestP,
  faInstagram,
  faYoutube,
  faXTwitter
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faSearch,
  faTimes,
  faAngleRight,
  faPhoneAlt,
  faAngleLeft,
  faMapMarkerAlt,
  faEnvelope,
  faFacebookF,
  faFacebookSquare,
  faPinterestP,
  faInstagram,
  faYoutube,
  faXTwitter,
  faPrint,
  faPlus,
  faMinus,
  faAngleDown,
  faCalendarAlt,
  faClock,
  faCalendar,
  faRss,
  faArrowAltCircleRight,
  faLongArrowAltRight,
  faLongArrowAltLeft
);

dom.watch();
